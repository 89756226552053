const saveCookieConsent = bool => {
	setCookie('amp_ch_cookie', bool, 360)
	window.location.reload()
}

document.addEventListener('DOMContentLoaded', () => {
	const cookieBanner = document.querySelector('.cookie-banner')
	if (getCookie('amp_ch_cookie') === "") {
		cookieBanner.classList.add('active')
	} else {
		cookieBanner.classList.remove('active')		
	}
})

const setCookie = (cname, cvalue, exdays) => {
	const d = new Date();
	d.setTime(d.getTime() + (exdays*24*60*60*1000));
	let expires = "expires="+ d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
const getCookie = (cname) => {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for(let i = 0; i <ca.length; i++) {
	  let c = ca[i];
	  while (c.charAt(0) == ' ') {
		c = c.substring(1);
	  }
	  if (c.indexOf(name) == 0) {
		return c.substring(name.length, c.length);
	  }
	}
	return "";
}
// function handleCookieBanner(cookieBanner, type, status, duration, cookie) {
//     var cookieBannerHeight = cookieBanner.outerHeight();
//     if (!duration) {
//       duration = 200;
//     }
//     if (cookie) {
//       cookieBanner.addClass("cookie");
//       jQuery(".openFormBanner").addClass("openFormCookie");
//     }
//     if (type == "overContent") {
//       if (!jQuery("body").hasClass("cookie-banner-overContent")) {
//         jQuery("body").addClass("cookie-banner-overContent");
//         cookieBanner.data("type", "overContent");
//       }
//       if (status == "show") {
//         cookieBanner.slideToggle(duration);
//       } else if (status == "hide") {
//         cookieBanner.slideToggle(duration, function() {
//           jQuery("body").removeClass("cookie-banner-overContent");
//           jQuery("body").removeClass("cookie-banner-active");
//           jQuery(".openFormBanner").removeClass("openFormCookie");
//         });
//       } else {
//         console.log("unknown status");
//       }
//     } else {
//       console.log("unknown type");
//     }
//   }
  
//   function appendJS() {
//     jQuery
//       .ajax({
//         url: "./includes/tracking.html",
//         cache: !0
//       })
//       .done(function(e) {
//         jQuery("head").append(e);
//       });
//   }
  
//   $(function() {
//     "use strict";
//     $('a[href*="#"]:not([href="#"])').click(function() {
//       if (
//         location.pathname.replace(/^\//, "") ===
//           this.pathname.replace(/^\//, "") &&
//         location.hostname === this.hostname
//       ) {
//         var e = $(this.hash);
//         if ((e = e.length ? e : $("[name=" + this.hash.slice(1) + "]")).length)
//           return (
//             $("html, body").animate(
//               {
//                 scrollTop: e.offset().top
//               },
//               1e3
//             ),
//             !1
//           );
//       }
//     });
//   });
  
//   var setCookie = !0;
  
//   function setStatusCookie(e, n, t) {
//     var o = new Date();
//     o.setTime(o.getTime() + 31536e6);
//     var i = "expires='" + o.toUTCString() + "';";
//     t && (i = ""), (document.cookie = e + "=" + n + ";" + i + "path=/");
//   }
  
//   function cookieAlreadySet(e) {
//     for (var n = document.cookie.split(";"), t = !1, o = 0; o < n.length; o++) {
//       n[o].split("=")[0].trim() == e && (t = !0);
//     }
//     return t;
//   }
//   jQuery(document).on("click", ".cookie-banner-close", function(e) {
//     e.preventDefault(),
//       e.stopPropagation(),
//       jQuery(this)
//         .closest(".cookie-banner")
//         .hasClass("cookie") && (setStatusCookie("accepted", 1, !1), appendJS()),
//       handleCookieBanner(
//         jQuery(this).closest(".cookie-banner"),
//         jQuery(this)
//           .closest(".cookie-banner")
//           .data("type"),
//         "hide"
//       );
//   }),
//     jQuery(window).on("load resize orientationchange", function(e) {
//       if ("load" == e.type) {
//         cookieAlreadySet("accepted");
//         setCookie &&
//           !cookieAlreadySet("accepted") &&
//           ((setCookie = !1),
//           handleCookieBanner(
//             jQuery(".cookie-banner"),
//             "overContent",
//             "show",
//             !1,
//             !0
//           ));
//       }
//     }),
//     jQuery(document).ready(function(e) {
//       1 == cookieAlreadySet("accepted") && appendJS();
//     });
  