if (document.getElementById('date')) { 
    const dateContent = document.getElementById('date')
    const d = new Date()
    d.setDate(d.getDate() - 3)
    const day = d.getDate()
    const month = d.getMonth()
    const year = d.getFullYear()
    const dayName = d.getDay()
    const weekDays = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']
    const months = [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember'
    ]
    const monthName = months[month]
    const weekDayName = weekDays[dayName]
    const dayOutput = `${weekDayName}, ${day}. ${monthName} ${year}`
    dateContent.innerHTML = dayOutput
}