const counter = (el = String, max = Number, min = Number, lsName = String) => { 
    const counter = document.querySelectorAll(el)
    const randomNumber = Math.floor(Math.random() * (max - min) + min)
    
    localStorage.getItem(lsName) === null && localStorage.setItem(lsName, randomNumber);
    
    const output = localStorage.getItem(lsName)
    
    counter.forEach(c => c.innerHTML = output)
    
}

const decreaseCounter = (el = String, lsName = String) => {
    const updatedCounter = localStorage.getItem(lsName) - 1
    const counter = document.querySelectorAll(el)
    localStorage.setItem(lsName, updatedCounter)

    const output = localStorage.getItem(lsName)

    counter.forEach(c => c.innerHTML = output)
}

counter('.counter', 150, 80, 'amp__counter')

